import type { IMovie, IMovieState, ISeasons, TCrewData } from '~/types/movies';
import { defineStore } from 'pinia';
import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import maxBy from 'lodash-es/maxBy';
import meanBy from 'lodash-es/meanBy';
import { createDurationFormatter, getTimeRemaining } from '~/helpers/datetime';
import { useNavigator } from '~/composables/useNavigator';
import { pluralize } from '~/helpers/processing';

const formatDuration = createDurationFormatter('ru-RU', 'short', ['hours', 'minutes'], 1, true, false);

export const useMovieStore = defineStore('useMovieStore', () => {
  const { subscription, isSpecialTariff, user, state } = storeToRefs(useAuthStore());
  const { isAndroid, isIOS } = useNavigator();
  const { $pwa } = useNuxtApp();
  const { reachGoal, metricGoals } = useMetrics();
  const isTargetKgDomain = useState('isTargetKgDomain');

  const movie = ref<IMovieState | null>(null);
  const pending = ref(true);

  const isSubscribed = computed(() => subscription.value?.is_active ?? false);
  const hasObtained = computed(() => user.value?.has_obtained_films ?? false);
  const isAllowed = computed(() => isSubscribed.value || hasObtained.value);
  const videoIsFilm = computed(() => movie.value?.is_film ?? false);
  const videoIsAvailable = computed(() => movie.value?.is_available ?? false);
  const videoIsTimeline = computed(() => movie.value?.timeline ?? false);
  const isFree = computed(() => movie.value?.is_free ?? false);
  const availableTo = computed(() => movie.value?.available_to ?? null);
  const isAbleToBuy = computed(() => videoIsFilm.value && !isFree.value);
  const isSubscribeFilm = computed(() => videoIsFilm.value && isFree.value);
  const canWatchWithAdvertiseAll = computed(() => movie.value?.available_with_advertise_all ?? false);
  const isAvailableCountry = computed(() => movie.value?.list[0]?.series[0]?.is_available_country ?? false);
  const canWatchWithAdvertiseEpisode = computed(() =>
    movie.value?.timeline
      ? movie.value?.timeline.available_with_advertise
      : movie.value?.list[0].series[0].available_with_advertise,
  );
  const movieIsAvailableToWatch = computed(
    () =>
      (videoIsAvailable.value && isAbleToBuy.value) ||
      (!videoIsFilm.value && isSubscribed.value) ||
      (isSubscribeFilm.value && isSubscribed.value),
  );
  const isWatchForMoney = computed(() => (
    videoIsFilm.value && !videoIsAvailable.value && !isFree.value && !availableTo.value
  ));
  const buttonReachGoal = () => {
    if (movieIsAvailableToWatch.value) {
      return;
    }

    if (isWatchForMoney.value) {
      reachGoal(metricGoals.clickWatchForMoney);
    } else if (canWatchWithAdvertiseAll.value) {
      if (state.value.loggedIn && !isSubscribed.value) {
        reachGoal(metricGoals.clickWatchFree);
      }
    } else {
      reachGoal(metricGoals.clickWatchBySub);
    }
  };
  const buttonText = computed(() => {
    if (movieIsAvailableToWatch.value) {
      if ((isAndroid.value || isIOS.value) && !$pwa?.isPWAInstalled) {
        return isTargetKgDomain.value ? 'Варианты просмотра' : 'Смотреть в приложении';
      }
      if (videoIsTimeline.value) return 'Продолжить просмотр';
      return `Смотреть ${videoIsFilm.value ? 'фильм' : 'сериал'}`;
    } else {
      if (isWatchForMoney.value) {
        return `Смотреть от ${(movie.value?.sd_lease_price ?? 0) / 100} ${movie.value?.currency.sign}`;
      }
      if (canWatchWithAdvertiseAll.value) return 'Смотреть бесплатно';
      return 'Смотреть по подписке';
    }
  });
  const unpublishedSeries = computed(() => {
    const payload: string[] = [];
    movie.value?.list
      ?.map(season => season.series.filter(seria => seria.public === false))
      .filter(season => season.length > 0)
      .map(el => payload.push(`S${el[0].season > 9 ? el[0].season : `0${el[0].season}`}`));

    return payload;
  });
  const unpublishedSeasons = computed(() => {
    const payload: string[] = [];
    movie.value?.list
      ?.filter(season => season.public === false)
      .map(el => payload.push(`S${el.season_number > 9 ? el.season_number : `0${el.season_number}`}`));

    return payload;
  });
  // [FIX] Костыль для фронта, лекарство — нужно чтобы бек перекрашивал все серии в public = false если весь сезон снимается с публикации
  const unpublishedContent = computed(() => {
    let payload: string[] = [];
    payload = [...unpublishedSeasons.value, ...unpublishedSeries.value];
    return payload;
  });
  const showUnpublishedTag = computed(() =>
    Boolean(isSpecialTariff.value && (!movie?.value?.public || unpublishedContent.value?.length)),
  );
  const bestQuality = computed(() => {
    return maxBy(movie.value?.qualities, quality => Number(quality.name));
  });
  const audio = computed(() => (movie.value?.audio_five_one ? 'dolby' : 'stereo'));
  const duration = computed(() => {
    const series = movie.value?.list?.[0]?.series ?? [];
    return formatDuration(meanBy(series, o => o.duration) * 1000);
  });
  const seasonList = computed<ISeasons[]>(() => movie.value?.list ?? []);
  const seasonStartIndex = computed(() => movie.value?.timeline?.season ?? seasonList.value?.[0]?.season_number);
  const breadcrumbs = computed(() => [
    movie.value?.is_film ? 'Фильмы' : 'Сериалы',
    ...(movie.value?.genre.map(el => el.name) ?? []),
  ]);
  const crew = computed<TCrewData>(() => {
    return {
      directors: movie.value?.directors || [],
      actors: movie.value?.actors || [],
      producers: movie.value?.producers || [],
      scriptwriters: movie.value?.scriptwriters || [],
    };
  });
  const rentRemain = computed(() => {
    if (!movie.value?.available_to) return '';
    const hours = getTimeRemaining(movie.value.available_to, 'hours');
    return `До конца аренды осталось ${hours} ${pluralize(hours, ['час', 'часа', 'часов'])}`;
  });

  const showUnpublishedSeasonTag = (seasonNumber: number) => {
    const expression = seasonNumber < 9 ? `S0${seasonNumber}` : `S${seasonNumber}`;
    return showUnpublishedTag.value && unpublishedContent.value.includes(expression);
  };
  const setMovieData = (payload?: IMovie | null) => {
    if (!payload) return;
    const data = cloneDeep(payload);
    movie.value = {
      ...data,
      backdrop: data?.backdrop || data?.backdrop_middle,
      countries: data?.country_original?.map(el => el.name),
    };
    pending.value = false;
  };
  const $reset = () => {
    movie.value = null;
    pending.value = true;
  };

  return {
    movie,
    isAbleToBuy,
    movieIsAvailableToWatch,
    canWatchWithAdvertiseAll,
    isAvailableCountry,
    showUnpublishedTag,
    unpublishedContent,
    pending,
    bestQuality,
    crew,
    audio,
    duration,
    breadcrumbs,
    seasonList,
    seasonStartIndex,
    buttonText,
    rentRemain,
    showUnpublishedSeasonTag,
    setMovieData,
    $reset,
    buttonReachGoal,
  };
});
